@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply w-full;
  @apply h-full;
}
